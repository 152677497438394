import React from 'react';
import { Box, Image } from 'grommet';
import { navigate } from 'gatsby'
import { Button } from '@appkit4/react-components/button';
import SidebarCDIR from '../../components/SidebarCDIR';

export default function PersonaCDIR() {
  const onClickButton_CDIRMyLearning = () => {
    navigate ('/cyber-defense-incident-responder')
  }
  const onClickButton_CDIIRExplore = () => {
    navigate ('/cyber-defense-incident-responder/cdir-explore')
  } 
  return (
    <Box
      background={{
      color: '#ffb600'
    }} 
      width= {'100vw'}
      height= {'92vh'}
      direction={'row'}
    >
      <Box pad={{top: '40px', horizontal: "large"}} 
      align={'top'}
      direction={'row'}
      alignContent="between" 
      gap="medium">
        <SidebarCDIR />
        <Box style={{marginTop: '10px', overflow: 'scroll', borderRadius: '.5em', maxHeight: '90%', maxWidth: '60vw'}}>
          <Box style={{position: 'relative'}}>
            <Image src="https://images.ctfassets.net/fwgo2d14oeir/3LtVBGPwLtplu09viw1c0w/a3c504665458720aeda94b5d428116e2/dis-pe-cdir-incident-ss.png" fill="horizontal" style={{ position: 'relative', borderRadius: '.5em'}} />
            <Button style={{width: '80px', position: 'absolute', top: '10px', left: '22%'}} kind='text' onClick={onClickButton_CDIRMyLearning} ><div className='beacon'></div>&nbsp; &nbsp;</Button>
            <Button style={{width: '80px', position: 'absolute', top: '10px', left: '33%'}} kind='text' onClick={onClickButton_CDIIRExplore} ><div className='beacon'></div>&nbsp; &nbsp;</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}