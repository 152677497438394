import React from 'react';
import { navigate } from 'gatsby'
import { Box, Card, CardHeader, CardBody, CardFooter, Heading,  Image, Paragraph } from 'grommet';

export default function SidebarCDIR() {
  const onClickButton_CDIRHome = () => {
    navigate ('/cloud-security-architect')
  }
  return (
    <Box pad={{top: '10px'}} direction="column" alignContent="between" gap="medium" style={{maxWidth: '320px', width: '100%', height: 'max-content'}}>
      <Card background="light-1" style={{borderRadius: '.5em', overflow: 'visible', boxShadow: 'none'}}>
      <CardHeader gap="small">
        <Box style={{border: '4px solid #F8F8F8', borderRadius: '50%', margin: '-30px 0 0 20px', maxWidth: '110px', width: '100%'}}><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/n7K8gPNs4VrkH9PC0Duue/a63d64915d690ffb35f4d039c606eeba/pe-persona-responder-avatar.jpg" style={{borderRadius: '50%'}} onClick={onClickButton_CDIRHome}/></Box>
        <Heading level={4} style={{paddingTop: '10px'}}>Cyber Defense Incident Responder</Heading>
        </CardHeader>
        <CardBody pad="medium">
          <Paragraph style={{fontSize: '15px', lineHeight: '1.4', marginBottom: '20px'}}>The Cyber Defense Incident Responder (may also be known as an Incident Handler, IR Coordinator, or IR Analyst) investigates, analyses, and responds to cyber threats against a company’s network and systems.</Paragraph>
          <Heading level={4} style={{marginBottom: '10px'}}>Challenges</Heading>
          <ul style={{fontSize: '15px', lineHeight: '1.5', marginBottom: '10px', padding: '0 0 0 15px'}}>
            <li>Staying abreast of current and emerging cyber threats and vulnerabilities</li>
            <li>Easily finding certification and training for topics like malware, triaging, and forensics</li>
            <li>Identifying the skills required for analysts and responders and developing a training curriculum for these resources</li>
          </ul>
        </CardBody>
      </Card>
      <Paragraph style={{fontSize: '18px', lineHeight: '1.5', fontWeight: '700', padding: '0 20px'}}>To learn more, reach out to your PwC Sponsor.</Paragraph>
    </Box>
  );
}